import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { isNil } from 'lodash';

import { LocalStorageService } from '@cjm/shared/core';
import { VloketLink } from '@cjm/shared/ui/common';

import { SidebarItemEntity } from '../../interfaces';

@Injectable({
	providedIn: 'root'
})
export class SidebarService {
	// Denis: The subjects below are for internal use and are exposed as public Observables in the lines below.
	private readonly isActiveSignal: WritableSignal<boolean> = signal<boolean>(false);
	private readonly sidebarHeaderTitleSignal: WritableSignal<string> = signal<string>('');
	private readonly sidebarHeaderLinkSignal: WritableSignal<VloketLink> = signal<VloketLink>(null);
	private readonly sidebarItemsSignal: WritableSignal<SidebarItemEntity[]> = signal<SidebarItemEntity[]>([]);
	private readonly sidebarItemsActiveFirstSignal: WritableSignal<SidebarItemEntity[]> = signal<SidebarItemEntity[]>(
		[]
	);
	private readonly isCollapsedSignal: WritableSignal<boolean> = signal<boolean>(false);
	private readonly sidebarActionCountSignal: WritableSignal<Record<string, number>> = signal<Record<string, number>>(
		{}
	);

	/**
	 * isActive$ indicates if the sidebar is currently set to active,
	 * which will tell the cjm-sidebar component to render the projected content with or without
	 * wrapping it in the sidebar layout.
	 *
	 * The default value is set to false.
	 */
	public readonly isActive: Signal<boolean> = this.isActiveSignal.asReadonly();
	/**
	 * sidebarHeaderTitle$ contains a title for the sidebar.
	 *
	 * The default value is set to ''.
	 */
	public readonly sidebarHeaderTitle: Signal<string> = this.sidebarHeaderTitleSignal.asReadonly();
	/**
	 * sidebarSubtitle$ contains a subtitle for the sidebar.
	 *
	 * The default value is set to ''.
	 */
	public readonly sidebarHeaderLink: Signal<VloketLink> = this.sidebarHeaderLinkSignal.asReadonly();
	/**
	 * sidebarItems$ contains the sidebar items.
	 *
	 * The default value is set to [].
	 */
	public readonly sidebarItems: Signal<SidebarItemEntity[]> = this.sidebarItemsSignal.asReadonly();
	/**
	 * sidebarItemsActiveFirst$ contains the sidebar items with the active one at the top.
	 *
	 * The default value is set to [].
	 */
	public readonly sidebarItemsActiveFirst: Signal<SidebarItemEntity[]> =
		this.sidebarItemsActiveFirstSignal.asReadonly();
	/**
	 * isCollapsed$ indicates if the sidebar is currently set to active,
	 * which will tell the cjm-sidebar component to render the projected content with or without
	 * wrapping it in the sidebar layout.
	 */
	public readonly isCollapsed: Signal<boolean> = this.isCollapsedSignal.asReadonly();

	public readonly sidebarActionCount: Signal<Record<string, number>> = this.sidebarActionCountSignal.asReadonly();

	// Denis: Keep the localstorage key in a property to avoid typos when using it in the service.
	private readonly sidebarCollapsedLSKey: string = 'isSidebarCollapsed';

	constructor(private readonly localStorageService: LocalStorageService) {
		/**
		 * When the service constructs, retrieve a potential value from localstorage for the collapsed state.
		 */
		this.isCollapsedSignal.set(this.localStorageService.getItem(this.sidebarCollapsedLSKey) === 'true');
	}

	/**
	 * setSidebarActive
	 *
	 * The setSidebarActive method is used to push a new value to the isActive$ stream through the isActiveSubject$.
	 *
	 * @param isActive - default false
	 */
	public setSidebarActive(isActive: boolean = false): void {
		this.isActiveSignal.set(isActive);
	}

	/**
	 * setSidebarHeader
	 *
	 * The setSidebarHeader method is used to push a new value to sidebarTitleSubject$ and sidebarHeaderLinkSubject$ subjects.
	 *
	 * @param title
	 * @param link
	 */
	public setSidebarHeader(title: string, link?: VloketLink): void {
		this.sidebarHeaderTitleSignal.set(title);

		if (link) {
			this.sidebarHeaderLinkSignal.set(link);
		}
	}

	/**
	 * setSidebarItems
	 *
	 * The setSidebarItems method is used to push a new value to the sidebarItems$ stream through the sidebarItemsSubject$.
	 *
	 * @param sidebarItems - default []
	 */
	public setSidebarItems(sidebarItems: SidebarItemEntity[] = []): void {
		const activeFirst = [...sidebarItems].sort(
			(itemA: SidebarItemEntity, itemB: SidebarItemEntity): number => Number(itemB.active) - Number(itemA.active)
		);

		this.sidebarItemsSignal.set(sidebarItems);
		this.sidebarItemsActiveFirstSignal.set(activeFirst);
	}

	/**
	 * setSidebarCollapsed
	 *
	 * The setSidebarCollapsed method is used to push a new value to the sidebarCollapsed$ stream through the sidebarCollapsedSubject$.
	 *
	 * It will also store the current value in local storage so that the setting persists over page refreshes.
	 *
	 * @param isCollapsed - default false
	 */
	public setSidebarCollapsed(isCollapsed: boolean = false): void {
		this.localStorageService.setItem(this.sidebarCollapsedLSKey, String(isCollapsed));
		this.isCollapsedSignal.set(isCollapsed);
	}

	/**
	 * setSidebarActionCount
	 *
	 * The setSidebarActionCount method is used to push a new value to the sidebarActionCount signal through the sidebarActionCountSignal.
	 *
	 * @param actionCount - default {}
	 */
	public setSidebarActionCount(actionCount: Record<string, number> = {}): void {
		if (isNil(actionCount)) {
			return;
		}

		this.sidebarActionCountSignal.set(actionCount);
	}

	/**
	 * clearSidebar
	 *
	 * The clearSidebar method will push default values to all subjects, cleaning existing data out of the streams.
	 *
	 * The isCollapsedSubject$ is ignored, because it is hydrated with the localstorage value in the constructor.
	 */
	public clearSidebar(): void {
		this.sidebarHeaderTitleSignal.set('');
		this.sidebarHeaderLinkSignal.set(null);
		this.sidebarItemsSignal.set([]);
		this.isActiveSignal.set(false);
	}
}
