import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatBadge } from '@angular/material/badge';
import { RouterLink } from '@angular/router';

import { MediaQueryMax } from '@cjm/shared/types';
import { FaIconComponent } from '@cjm/shared/ui/common';

import { SidebarItemEntity } from './sidebar-item.types';

@Component({
	selector: 'cjm-sidebar-item',
	templateUrl: './sidebar-item.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgClass, RouterLink, NgTemplateOutlet, FaIconComponent, MatBadge]
})
export class SidebarItemComponent {
	/**
	 * Provide an item for the component to render.
	 */
	@Input() public item: SidebarItemEntity;
	/**
	 * Disable the label and switch to an icon-only view.
	 *
	 * Default value is set to 'false'.
	 */
	@Input() public iconOnly: boolean = false;
	/**
	 * Provide a badge count to display on the item.
	 */
	@Input() public badgeCount: number;
	/**
	 * This event will emit when the "Toggle all" button is clicked.
	 *
	 * This output can only be used in the mobile view (below 'desktop' breakpoint).
	 */
	@Output() public toggleAll: EventEmitter<void> = new EventEmitter<void>();

	public readonly mediaQueryMax: typeof MediaQueryMax = MediaQueryMax;

	/**
	 * toggleAllItems
	 *
	 * The toggleAllItems method is used to trigger the toggleAll event emitter.
	 * This will let the parent (cjm-sidebar) know that it needs to show/hide
	 * all sidebar items in the mobile view.
	 */
	public toggleAllItems(): void {
		this.toggleAll.emit();
	}
}
